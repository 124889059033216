export const getUniqueArray = ({
  array,
  key,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  array: any[]
  key: string
}) => [
  ...new Map(
    array.map((item) => {
      return [item[key], item]
    }),
  ).values(),
]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const generateFormResponse = ({ formData }: { formData: any }) => {
  let textString = ''

  const capitalise = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)

  Object.keys(formData).forEach((key) => {
    const isOnlyOneInfo = Object.keys(formData).length === 1
    let name = ''

    if (!isOnlyOneInfo)
      textString += `${capitalise(key.replaceAll(/_/g, ' '))}:\n`

    Object.values(formData[key]).forEach((value, index) => {
      if (value) {
        if (index === 0) {
          return (name += value)
        }
        if (index === 1) {
          name += ` ${value}`
          return (textString += name)
        }
        return (textString += `, ${value}`)
      }
    })

    textString += '\n\n'
  })

  return textString.trimEnd()
}

import { v4 } from 'uuid'
import { useCookies } from 'react-cookie'
import Linkify from 'linkify-react'

import { SEND_MESSAGE, socketIdCookieKey } from '../../constants'
import { MessageItem } from '../../interfaces'
import { ListItem } from '../ListItem'
import { createNewMessage } from '../../utils'
import { useBoundStore } from '../../store'
import { useChatContext } from '../Chat'
import { useMessageBoxContext } from '../MessageBox'
import { CalendarDialog, ListDialog } from '../Dialogs'
import { ApiMessage } from './ApiMessage'

type InlineOptionsMessageProps = {
  title: string
  displayAlternatives: boolean
  items: MessageItem[] | undefined
  showLogo?: boolean
}

const linkifyOptions = {
  defaultProtocol: 'https',
  rel: 'noreferrer',
  target: '_blank',
}

export const InlineOptionsMessage = ({
  title,
  displayAlternatives,
  items,
  showLogo,
}: InlineOptionsMessageProps) => {
  const [cookies] = useCookies([socketIdCookieKey])
  const clientId = useBoundStore((state) => state.clientId)
  const { sendJsonMessage } = useChatContext()
  const { messages, updateMessages } = useMessageBoxContext()

  const handleAlternativeClick = (alternative: MessageItem) => {
    if (alternative.action.type === 'TEXT') {
      const newMessage = {
        type: SEND_MESSAGE,
        id: v4(),
        timestamp: Date.now(),
        data: { id: v4(), text: alternative.action.text },
      }
      const userMessage = createNewMessage({
        id: newMessage.id,
        text: newMessage.data.text as string,
        senderId: cookies.frontdeskaisocketid,
        timestamp: newMessage.timestamp,
        clientId,
      })
      updateMessages([...messages, userMessage])
      sendJsonMessage(newMessage)
    }
  }

  return (
    <>
      <ListItem
        showLogo={showLogo}
        additionalClasses="!tl-text-black !tl-bg-gray-300 tl-rounded-br-md"
      >
        <Linkify
          className="tl [&>a]:tl-underline"
          as="span"
          options={linkifyOptions}
        >
          {title}
        </Linkify>
      </ListItem>
      {displayAlternatives
        ? items?.map((alternative) => {
            if (alternative.action.type === 'TEXT') {
              return (
                <ListItem
                  onClick={() => handleAlternativeClick(alternative)}
                  key={alternative.title}
                  additionalClasses="tl-rounded-xl tl-rounded-t-xl tl-border-brand tl-border tl-text-default tl-text-gray-900 tl-cursor-pointer hover:tl-text-white hover:tl-bg-brand"
                >
                  {alternative.title}
                </ListItem>
              )
            }

            if (alternative.action.type === 'OPTIONS') {
              return (
                <ListDialog
                  key={v4()}
                  title={alternative?.action.title as string}
                  actionTitle={alternative.action.action_title || 'SEND'}
                  searchable={alternative.action.search_enabled}
                  items={alternative.action.items}
                  searchPlaceholder={alternative.action.search_prompt}
                  notFoundString={alternative.action.empty_search?.text}
                  minSelection={alternative.action.min_selection || 1}
                  maxSelection={alternative.action.min_selection || 1}
                  openButton={
                    <ListItem additionalClasses="tl-rounded-xl tl-rounded-t-xl tl-border-brand tl-border tl-text-default tl-text-gray-900 tl-cursor-pointer hover:tl-text-white hover:tl-bg-brand">
                      {alternative.title}
                    </ListItem>
                  }
                />
              )
            }

            if (alternative.action.type === 'CALENDAR') {
              return (
                <CalendarDialog
                  key={alternative.title}
                  title={alternative.action.title as string}
                  actionTitle={alternative.action.action_title}
                  before={alternative.action.before}
                  after={alternative.action.after}
                  openButton={
                    <ListItem additionalClasses="tl-rounded-xl tl-rounded-t-xl tl-border-brand tl-border tl-text-default tl-text-gray-900 tl-cursor-pointer hover:tl-text-white hover:tl-bg-brand">
                      {alternative.title}
                    </ListItem>
                  }
                />
              )
            }

            if (alternative.action.type === 'API') {
              return (
                <ApiMessage
                  key={alternative.title}
                  body={alternative.action.body}
                  url={alternative.action.url}
                  verb={alternative.action.verb}
                  openButton={alternative.title}
                />
              )
            }

            return null
          })
        : null}
    </>
  )
}

import { StateCreator } from 'zustand'

interface IFormSlice {
  formsSubmitted: string[]
  addSubmittedForm: (formId: string) => void
}

const createFormSlice: StateCreator<IFormSlice, [], [], IFormSlice> = (
  set,
) => ({
  formsSubmitted: [],
  addSubmittedForm: (formId) =>
    set((state) => ({ formsSubmitted: [...state.formsSubmitted, formId] })),
})

export type { IFormSlice }
export { createFormSlice }

const getRGBColor = (hex?: string) => {
  // in case we fail to get backend data we won't override Tailwind defaults
  if (!hex) return `--color-undefined: 255, 255, 255;`

  // rgb values
  const r = parseInt(hex.substring(1, 3), 16)
  const g = parseInt(hex.substring(3, 5), 16)
  const b = parseInt(hex.substring(5, 7), 16)

  return `${r}, ${g}, ${b}`
}

export { getRGBColor }

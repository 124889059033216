import * as React from 'react'
import {
  startOfWeek,
  format,
  eachDayOfInterval,
  endOfWeek,
  isToday as isTodayFNS,
  isBefore,
  isEqual,
  startOfDay,
  addMonths,
  isAfter,
  addDays,
  subDays,
} from 'date-fns'

export const useCalendar = () => {
  const [date] = React.useState<Date>(startOfDay(new Date()))
  const [selectedDate, setSelectedDate] = React.useState<Date>(date)
  const [currentWeek, setCurrentWeek] = React.useState<number>(0)

  // minimum date is today
  const minDate = startOfDay(new Date())
  // max date set to 1 month from today
  const maxDate = addMonths(minDate, 1)

  // const currentMonth = date.toLocaleString('en-US', { month: 'long' })
  // const currentYear = date.toLocaleString('en-US', { year: 'numeric' })

  const daysOfWeek = eachDayOfInterval({
    start: startOfWeek(date, { weekStartsOn: 1 }),
    end: endOfWeek(date, { weekStartsOn: 1 }),
  })

  const weekDays = daysOfWeek.map((day) => format(day, 'eee'))

  const fullDays = eachDayOfInterval({
    start: startOfWeek(minDate, { weekStartsOn: 1 }),
    end: endOfWeek(maxDate, { weekStartsOn: 1 }),
  })

  const fullCalendar = fullDays.map((weekDay) => {
    const isToday = isTodayFNS(weekDay)
    const isBeforeToday = isBefore(weekDay, minDate)

    return {
      date: weekDay,
      dayNumber: format(weekDay, 'dd'),
      isToday,
      isBeforeToday,
      onClick: () => {
        if (isBeforeToday && !isToday) return
        return setSelectedDate(weekDay)
      },
      isSelected: isEqual(selectedDate, weekDay),
      eventDate: format(weekDay, 'yyyy-MM-dd'),
    }
  })

  const currentWeekStartsOn = startOfWeek(fullDays[currentWeek], {
    weekStartsOn: 1,
  })
  const currentWeekEndsOn = endOfWeek(fullDays[currentWeek + 5], {
    weekStartsOn: 1,
  })
  const weekStartDate = format(currentWeekStartsOn, 'yyyy-MM-dd')
  const weekEndDate = format(currentWeekEndsOn, 'yyyy-MM-dd')
  const disableBackArrow = isBefore(subDays(currentWeekStartsOn, 1), minDate)
  const disableForwardArrow = isAfter(addDays(currentWeekEndsOn, 1), maxDate)
  const currentMonth = currentWeekStartsOn.toLocaleString('en-US', {
    month: 'long',
  })
  const currentYear = currentWeekStartsOn.toLocaleString('en-US', {
    year: 'numeric',
  })
  const currentWeekDatesInterval = eachDayOfInterval({
    start: startOfWeek(currentWeekStartsOn, { weekStartsOn: 1 }),
    end: endOfWeek(currentWeekEndsOn, { weekStartsOn: 1 }),
  }).map((day) => format(day, 'yyyy-MM-dd'))

  const advanceOneWeek = () => setCurrentWeek(currentWeek + 7)
  const goBackOneWeek = () => setCurrentWeek(currentWeek - 7)

  const selectedDateString = format(selectedDate, 'yyyy-MM-dd')

  return {
    currentMonth,
    currentYear,
    weekDays,
    advanceOneWeek,
    goBackOneWeek,
    disableBackArrow,
    disableForwardArrow,
    selectedDateString,
    fullCalendar,
    weekStartDate,
    weekEndDate,
    currentWeekDatesInterval,
  }
}

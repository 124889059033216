import { StateCreator } from 'zustand'

interface INotificationsSlice {
  notificationsCount: number
  addNotification: () => void
  markNotificationsAsRead: () => void
}

const createNotificationsSlice: StateCreator<
  INotificationsSlice,
  [],
  [],
  INotificationsSlice
> = (set) => ({
  notificationsCount: 0,
  addNotification: () =>
    set((state) => ({ notificationsCount: state.notificationsCount + 1 })),
  markNotificationsAsRead: () =>
    set(() => ({
      notificationsCount: 0,
    })),
})

export type { INotificationsSlice }
export { createNotificationsSlice }

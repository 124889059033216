import * as React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { CookiesProvider } from 'react-cookie'

import { Chat } from './components'
import { cookieExpiry } from './constants'
import './index.css'
import { useBoundStore } from './store'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  },
})

type AppProps = {
  id: string
  notificationsound: boolean
}

const App: React.FC<AppProps> = ({ id, notificationsound = true }) => {
  console.log(
    '%cTrueLark Webchat V1.0.1-calendar',
    'font-size:1rem;color:tomato',
  )
  const setClientId = useBoundStore((state) => state.setClientId)
  const setNotificationSound = useBoundStore(
    (state) => state.setNotificationSound,
  )

  // TODO: change to a better approach
  // https://weblog.west-wind.com/posts/2023/Apr/17/Preventing-iOS-Safari-Textbox-Zooming
  React.useEffect(() => {
    if (navigator.userAgent.indexOf('iPhone') > -1) {
      document
        ?.querySelector('[name=viewport]')
        ?.setAttribute(
          'content',
          'width=device-width, initial-scale=1, maximum-scale=1',
        )
    }
    // adding our TL prefix to html and body tags
    document.getElementsByTagName('html')[0].classList.add('tl')
    document.getElementsByTagName('body')[0].classList.add('tl')
  }, [])

  React.useEffect(() => {
    setClientId(id)
    setNotificationSound(notificationsound)
  }, [id, setClientId, notificationsound, setNotificationSound])

  return (
    <CookiesProvider
      defaultSetOptions={{
        maxAge: cookieExpiry,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <Chat />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </CookiesProvider>
  )
}

export default App

import * as React from 'react'
import { createPortal } from 'react-dom'
import { v4 } from 'uuid'
import { useCookies } from 'react-cookie'

import { Close, MagnifyingGlass } from '../../assets'
import { useReactQuerySubscription } from '../../hooks'
import { SEND_MESSAGE, socketIdCookieKey } from '../../constants'
import { useMessageBoxContext } from '../MessageBox'
import { createNewMessage, isElementScrollable } from '../../utils'
import { useBoundStore } from '../../store'

export interface IItem {
  title: string
  subtitle: string
  leading: string | null
  trailing: string | null
  action: {
    type: string
    text: string
  }
}

interface IListDialogProps {
  title: string
  actionTitle: string | undefined
  items: IItem[] | undefined
  openButton: React.ReactElement | null
  minSelection: number
  maxSelection: number
  searchable?: boolean
  searchPlaceholder?: string
  notFoundString?: string
}

export function ListDialog({
  title,
  actionTitle,
  items,
  openButton,
  searchable,
  searchPlaceholder,
  notFoundString,
  minSelection,
  maxSelection,
}: IListDialogProps) {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [selectedItems, setSelectedItems] = React.useState<IItem[]>()
  const [searchValue, setSearchValue] = React.useState<string>('')
  const { sendJsonMessage } = useReactQuerySubscription()
  const { messages, updateMessages } = useMessageBoxContext()
  const [cookies] = useCookies([socketIdCookieKey])
  const clientId = useBoundStore((state) => state.clientId)
  const disabled = selectedItems?.length !== minSelection
  const trimSearchVal = searchValue?.toLowerCase().trim()
  const contentRef = React.useRef<HTMLDivElement>(null)
  const isContentScrollable = isElementScrollable({
    element: contentRef.current,
  })

  const filteredItems = items?.filter(
    (item) =>
      item.title.toLowerCase().trim().includes(trimSearchVal) ||
      item?.subtitle?.toLowerCase()?.trim()?.includes(trimSearchVal),
  )

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }

  const handleAction = () => {
    const getMessageText = () => {
      if (maxSelection === 1) {
        return selectedItems?.[0].action.text
      }

      const concatText: string[] = []
      selectedItems?.forEach((item) => concatText.push(item.action.text))

      return concatText.join('\n')
    }

    const newMessage = {
      type: SEND_MESSAGE,
      id: v4(),
      timestamp: Date.now(),
      data: { id: v4(), text: getMessageText() },
    }

    const userMessage = createNewMessage({
      id: newMessage.id,
      text: newMessage.data.text as string,
      senderId: cookies.frontdeskaisocketid,
      timestamp: newMessage.timestamp,
      clientId,
    })

    updateMessages([...messages, userMessage])
    sendJsonMessage(newMessage)
    setIsOpen(false)
  }

  const handleSelectItem = (item: IItem) => {
    setSelectedItems((items) => {
      if (items?.some((i) => i.title === item.title))
        return items.filter((i) => i.title !== item.title)

      if (
        maxSelection === 1 ||
        (items?.length !== maxSelection && !items?.length)
      )
        return [item]

      if (items?.length !== maxSelection && items?.length)
        return [...items, item]

      return items
    })
  }

  return (
    <>
      {openButton
        ? React.cloneElement(
            openButton,
            { onClick: () => setIsOpen(true) },
            ...(Array.isArray(openButton.props.children)
              ? openButton.props.children
              : [openButton.props.children]),
          )
        : null}
      {createPortal(
        <dialog
          open={isOpen}
          className={`tl tl-p-0 tl-flex tl-flex-col tl-absolute tl-left-0 tl-w-full tl-h-full tl-z-10 tl-rounded-md ${
            isOpen ? 'tl-top-0' : 'tl-top-[9999rem]'
          }`}
        >
          {/* HEADER */}
          <div className="tl tl-bg-brand tl-px-6 tl-py-4 tl-flex tl-items-center tl-flex-0 tl-justify-between tl-rounded-t-md">
            <span className="tl tl-antialiased tl-text-white tl-font-semibold tl-text-n tl-uppercase">
              {title}
            </span>
            <div
              className="tl tl-cursor-pointer"
              onClick={() => setIsOpen(false)}
            >
              <Close color="#fff" size="14" />
            </div>
          </div>
          {/* CONTENTS */}
          <div
            ref={contentRef}
            className="tl tl-flex-auto tl-p-4 tl-overflow-y-scroll scrollbar scrollbar-w-[5px] scrollbar-rounded-full scrollbar-thumb-gray-400"
          >
            {searchable ? (
              <div className="tl tl-flex tl-items-center tl-gap-4 tl-border-b-2 tl-pb-2 tl-mb-4">
                <div className="tl">
                  <MagnifyingGlass />
                </div>
                <input
                  className="tl tl-w-full tl-outline-none tl-antialiased tl-text-default tl-text-[#5F6A79]"
                  type="text"
                  placeholder={searchPlaceholder}
                  value={searchValue}
                  onChange={handleChangeSearch}
                />
              </div>
            ) : null}
            <div className="tl tl-flex tl-gap-2 tl-flex-col">
              {filteredItems?.length ? (
                filteredItems.map((item) => (
                  <div
                    className={`tl tl-py-4 tl-px-4 tl-bg-gray-300 tl-rounded-md tl-cursor-pointer tl-border sm:hover:tl-bg-white sm:hover:tl-border-brand ${
                      selectedItems?.some((el) => el.title === item.title)
                        ? 'tl-bg-white tl-border-brand'
                        : 'tl-border-transparent'
                    }`}
                    key={item.title}
                    onClick={() => handleSelectItem(item)}
                  >
                    <div className="tl tl-text-default tl-font-semibold tl-antialiased">
                      {item.title}
                    </div>
                    <div className="tl tl-text-[#5F6A79] tl-text-[11px] tl-antialiased">
                      {item.subtitle}
                    </div>
                  </div>
                ))
              ) : (
                <div className="tl tl-mt-[50%] tl-self-center tl-font-normal tl-text-default tl-text-black tl-antialiased tl-leading-5 tl-text-center">
                  {notFoundString}
                </div>
              )}
            </div>
          </div>
          {/* FOOTER */}
          <div
            className={`tl tl-p-5 tl-bg-white flex-0 tl-rounded-t ${
              isContentScrollable ? 'tl-shadow-mid' : ''
            }`}
          >
            <button
              onClick={handleAction}
              className={`tl-font-sans tl-border-0 tl-text-white tl-w-full tl-rounded-md tl-py-2 tl-text-sm tl-font-semibold tl-antialiased ${
                !disabled
                  ? 'tl-bg-brand tl-cursor-pointer'
                  : 'tl-bg-gray-50 tl-cursor-not-allowed'
              }`}
              disabled={disabled}
            >
              {actionTitle || 'Send'}
            </button>
          </div>
        </dialog>,
        document.getElementById('chat-box') as Element,
      )}
    </>
  )
}

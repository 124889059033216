import Lottie from 'lottie-react'
import { ListItem } from '../ListItem'
import { typingIndicator } from '../../assets'
import { useMessageBoxContext } from '../MessageBox'

export const AgentTyping = () => {
  const { events } = useMessageBoxContext()

  if (events?.isAgentTyping)
    return (
      <ListItem
        isBot
        showLogo
        additionalClasses="tl-bg-gray-300 tl-rounded-br-md tl-w-[4.5rem] tl-h-[2.7rem] tl-max-w-[4.5rem] tl-max-h-[2.7rem]"
      >
        <Lottie animationData={typingIndicator} />
      </ListItem>
    )

  return null
}

import { ListItem } from '../ListItem'

interface ILinkPreviewProps {
  metadata?: {
    audio?: string | null
    description?: string
    image?: string
    title?: string
    url?: string
    vide?: string | null
  }
  url?: string
}

export const LinkPreview = ({ metadata, url }: ILinkPreviewProps) => {
  const handleClick = () => window.open(metadata?.url || url)

  if (!metadata)
    return (
      <ListItem
        showLogo
        isBot
        additionalClasses="tl-bg-gray-300 tl-px-0 tl-py-0 tl-bt-1 tl-rounded-t-[10.62px] tl-cursor-pointer"
        onClick={handleClick}
      >
        <p className="tl tl-gray-900 tl-underline tl-text-default">{url}</p>
      </ListItem>
    )

  if (metadata && !metadata.image) {
    return (
      <ListItem
        showLogo
        isBot
        additionalClasses="tl-bg-gray-300 tl-px-0 tl-py-0 tl-bt-1 tl-rounded-t-[10.62px] tl-cursor-pointer"
        onClick={handleClick}
      >
        <div className="tl tl-text-n tl-leading-md">
          <p className="tl tl-font-bold tl-text-default">{metadata.title}</p>
          <p className="tl tl-text-default">{metadata?.url}</p>
        </div>
      </ListItem>
    )
  }

  return (
    <ListItem
      showLogo
      isBot
      removeDefaultClasses
      additionalClasses="tl-bg-gray-300 tl-px-0 tl-py-0 tl-bt-1 tl-rounded-t-[10.62px] tl-cursor-pointer tl-max-w-[95%]"
      onClick={handleClick}
    >
      <div className="tl tl-min-w-60 tl-min-h-36">
        {metadata?.image && (
          <img
            className="tl-w-full tl-h-full tl-object-cover tl-rounded-t-[10.62px]"
            src={metadata?.image}
            alt={`${metadata?.title} link preview`}
          />
        )}
      </div>
      <div className="tl tl-px-4 tl-py-3 tl-text-n tl-leading-md">
        <p className="tl tl-font-bold tl-text-default">{metadata?.title}</p>
        <p className="tl tl-text-default">{metadata?.url}</p>
      </div>
    </ListItem>
  )
}

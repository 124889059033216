import { useQueryClient } from '@tanstack/react-query'

import { IPersonalization } from '../interfaces'
import { queryKeys } from '../constants'
import { getRGBColor } from '../utils'
import { useBoundStore } from '../store'

const useDynamicColors = () => {
  const queryClient = useQueryClient()
  const personalization = queryClient.getQueryData<IPersonalization>(
    queryKeys.personalization,
  )
  const setBrandColor = useBoundStore((state) => state.setBrandColor)

  const brandColor = getRGBColor(personalization?.background_color)

  document.documentElement.style.setProperty('--brand-color', brandColor)

  if (personalization?.background_color) {
    setBrandColor(personalization?.background_color)
  }
}

export { useDynamicColors }

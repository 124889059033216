const ChevronDown = () => {
  return (
    <svg
      className="tl"
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.125 4.89844L11.8828 0.332031C12.0286 0.186198 12.1745 0.186198 12.3203 0.332031L13.168 1.15234C13.3138 1.29818 13.3138 1.44401 13.168 1.58984L7.34375 7.16797C7.28906 7.22266 7.21615 7.25 7.125 7.25C7.03385 7.25 6.96094 7.22266 6.90625 7.16797L1.08203 1.58984C0.936198 1.44401 0.936198 1.29818 1.08203 1.15234L1.92969 0.332031C2.07552 0.186198 2.22135 0.186198 2.36719 0.332031L7.125 4.89844Z"
        fill="white"
      />
    </svg>
  )
}

export { ChevronDown }

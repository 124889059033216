import { RJSFSchema } from '@rjsf/utils'

import { ListItem } from '../ListItem'

import { InlineOptionsButton } from './InlineOptionsButton'
import { MessageItem } from '../../interfaces'
import { ChatMessageText } from './ChatMessageText'
import { FormDialog, PaymentDialog } from '../Dialogs'
import { useMessageBoxContext } from '../MessageBox'
import { useBoundStore } from '../../store'
// import { guestInfoSchema } from '../../dev/forms'

interface IGroupMessageProps {
  messageGroup:
    | {
        status?: string
        text?: string
        type: string
        title?: string
        subtitle?: string
        display?: string
        leading?: string
        trailing?: null
        chat_enabled?: boolean
        items?: MessageItem[]
        metadata?: {
          audio?: string | null
          description?: string
          image?: string
          title?: string
          url?: string
          vide?: string | null
        }
      }[]
    | undefined
  nextMsg?: boolean | undefined
  messageId?: string
}

export const GroupMessage = ({
  messageGroup,
  nextMsg,
  messageId = '',
}: IGroupMessageProps) => {
  const { lastEvents } = useMessageBoxContext()
  const hasCCReceived = lastEvents.filter((ev) => ev.type === 'CC_RECEIVED')

  const formsSubmitted = useBoundStore((state) => state.formsSubmitted)

  return (
    <>
      {messageGroup?.map((message, i) => {
        if (message.type === 'TEXT') {
          return (
            <ChatMessageText
              key={message.text}
              text={message.text as string}
              showLogo={messageGroup.length === i + 1}
              metadata={message?.metadata}
            />
          )
        }

        if (
          message.display === 'BUTTON' &&
          message?.items?.[0].action.type === 'FORM'
        ) {
          const isFormSubmitted = formsSubmitted.includes(messageId)
          const generateSubtitle = () => {
            // form not submitted but have next message
            if (!isFormSubmitted && !!nextMsg) {
              const newMessage = message?.subtitle?.replace(
                /requested by/i,
                'Not shared with',
              )
              return newMessage || message.subtitle
            }

            // form submitted
            if (isFormSubmitted) {
              const newMessage = message?.subtitle?.replace(
                /requested by/i,
                'Shared with',
              )
              return newMessage || message.subtitle
            }

            // default message
            return message.subtitle
          }

          return (
            <FormDialog
              key={messageId}
              title={message.title as string}
              schema={message?.items?.[0].action.schema as RJSFSchema}
              // schema={guestInfoSchema}
              disableOpen={!!nextMsg}
              messageId={messageId}
              openButton={
                <ListItem useOnlyReceived showLogo>
                  <div className="tl tl-bg-gray-300 tl-py-3 tl-pl-3 tl-pr-4 tl-flex tl-flex-col tl-gap-3 tl-rounded-t-md tl-rounded-br-md">
                    <div className="tl tl-flex tl-items-center tl-gap-4">
                      <div className="tl tl-flex tl-items-center tl-justify-center tl-min-w-[2rem] tl-min-h-[2rem] tl-w-8 tl-h-8 tl-rounded-full tl-object-fill">
                        <img
                          src={message.leading}
                          alt={`${message.title} icon`}
                          className="tl"
                        />
                      </div>
                      <div className="tl tl-flex tl-flex-col tl-text-n tl-leading-md">
                        <span className="tl tl-font-bold tl-text-gray-900 tl-antialiased">
                          {message.title}
                        </span>
                        <span className="tl tl-font-medium tl-antialiased tl-text-[#5F6A79]">
                          {generateSubtitle()}
                        </span>
                      </div>
                    </div>
                    {!nextMsg ? (
                      <div className="tl">
                        <button className="tl-font-sans tl-border-0 tl-cursor-pointer tl-bg-white tl-py-3 tl-px-8 tl-font-bold tl-text-[#333] tl-rounded-lg tl-text-n tl-leading-md tl-w-full tl-antialiased">
                          {message?.items?.[0].title}
                        </button>
                      </div>
                    ) : null}
                  </div>
                </ListItem>
              }
            />
          )
        }

        if (
          message.display === 'BUTTON' &&
          message?.items?.[0].action.type === 'REDIRECT'
        ) {
          const ccStatus = message?.status
          const hideOpenButton =
            !!nextMsg || !!hasCCReceived.length || !!ccStatus

          const generateSubtitle = () => {
            if (nextMsg && !hasCCReceived.length && !ccStatus) {
              const newMessage = message?.subtitle?.replace(
                /requested by/i,
                'Not shared with',
              )
              return newMessage || message.subtitle
            }

            // cc received
            if (hasCCReceived.length || ccStatus) {
              const newMessage = message?.subtitle?.replace(
                /requested by/i,
                'Shared with',
              )
              return newMessage || message.subtitle
            }

            // default message
            return message.subtitle
          }

          return (
            <PaymentDialog
              key={message.title}
              title={message.title as string}
              disableOpen={!!nextMsg || !!hasCCReceived.length || !!ccStatus}
              url={message?.items?.[0].action.url as string}
              openButton={
                <div>
                  <ListItem useOnlyReceived showLogo>
                    <div className="tl tl-bg-gray-300 tl-py-3 tl-pl-3 tl-pr-4 tl-flex tl-flex-col tl-gap-3 tl-rounded-t-md tl-rounded-br-md">
                      <div className="tl tl-flex tl-items-center tl-gap-4">
                        <div className="tl tl-flex tl-items-center tl-justify-center tl-min-w-[2rem] tl-min-h-[2rem] tl-w-8 tl-h-8 tl-rounded-full tl-object-fill">
                          <img
                            src={message.leading}
                            alt={`${message.title} icon`}
                            className="tl"
                          />
                        </div>
                        <div className="tl tl-flex tl-flex-col tl-text-n tl-leading-md">
                          <span className="tl tl-font-bold tl-text-gray-900 tl-antialiased">
                            {message.title}
                          </span>
                          <span className="tl tl-font-medium tl-antialiased">
                            {generateSubtitle()}
                          </span>
                        </div>
                      </div>
                      {!hideOpenButton ? (
                        <div className="tl">
                          <button className="tl-font-sans tl-border-0 tl-cursor-pointer tl-bg-white tl-py-3 tl-px-8 tl-font-bold tl-text-[#333] tl-rounded-lg tl-text-n tl-leading-md tl-w-full tl-antialiased">
                            {message?.items?.[0].title}
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </ListItem>
                  {hasCCReceived.length || ccStatus ? (
                    <p className="tl !tl-mt-3 !tl-mb-4 tl-antialiased  tl-text-[10px] tl-leading-[13px] tl-text-[#5F6A79] tl-font-medium">
                      {!ccStatus
                        ? `You securely shared credit card info with
                      ${message?.subtitle?.replace(
                        /requested by/i,
                        '',
                      )}. Contact them if you need any changes to your payment method.`
                        : ccStatus}
                    </p>
                  ) : null}
                </div>
              }
            />
          )
        }

        if (message.display === 'BUTTON') {
          return (
            <InlineOptionsButton
              key={`${message.text} ${i}`}
              title={message?.title as string}
              subtitle={message?.subtitle}
              items={message?.items?.[0].action.items}
              actionTitle={message?.items?.[0]?.action?.action_title}
              searchable={message?.items?.[0]?.action?.search_enabled}
              searchPlaceholder={message?.items?.[0]?.action?.search_prompt}
              openTitle={message?.items?.[0]?.title}
              icon={message?.leading}
              emptySearch={message?.items?.[0]?.action?.empty_search?.text}
              minSelection={message?.items?.[0]?.action?.min_selection || 1}
              maxSelection={message?.items?.[0]?.action?.max_selection || 1}
              nextMsg={nextMsg}
            />
          )
        }

        return null
      })}
    </>
  )
}

import { NotificationsCount } from './NotificationsCount'

import { useBoundStore } from '../../store'
import { ChatRightText } from '../../assets'

const Bubble = () => {
  const toggleMessageBoxOpen = useBoundStore(
    (state) => state.toggleMessageBoxOpen,
  )
  const markNotificationsAsRead = useBoundStore(
    (state) => state.markNotificationsAsRead,
  )
  const messageBoxOpen = useBoundStore((state) => state.messageBoxOpen)

  const handleOpenClick = () => {
    toggleMessageBoxOpen()
    localStorage.removeItem('tl-bubble')
    markNotificationsAsRead()
  }

  return (
    <div
      onClick={handleOpenClick}
      className={`tl ${
        !messageBoxOpen
          ? 'tl-animate-openbubble tl-opacity-100 tl-bottom-0'
          : 'tl-animate-hidebubble tl-opacity-0 -tl-bottom-[150px]'
      } tl-bg-brand tl-text-white tl-relative tl-flex tl-h-16 tl-w-16 tl-cursor-pointer tl-flex-wrap tl-content-center tl-justify-center tl-rounded-full tl-shadow-lg tl-transition-all tl-duration-200 sm:hover:tl-scale-[1.07]`}
    >
      <ChatRightText />
      <NotificationsCount />
    </div>
  )
}

export { Bubble }

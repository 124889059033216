import * as React from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useCookies } from 'react-cookie'
import useSound from 'use-sound'

import { useBoundStore } from '../../store'
import { IEvent, IEvents, TMessage } from '../../interfaces'
import { queryKeys, socketIdCookieKey } from '../../constants'
import notificationSound from '../../assets/audio/open-ended.mp3'

import { MessageBoxChat } from './MessageBoxChat'
import { MessageBoxFooter } from './MessageBoxFooter'
import { MessageBoxHeader } from './MessageBoxHeader'
import { getUniqueArray } from '../../utils'
import { addDays, format, startOfWeek } from 'date-fns'

interface IMessageBoxContext {
  messages: TMessage[]
  updateMessages: React.Dispatch<React.SetStateAction<TMessage[]>>
  events: IEvents | undefined
  lastEvents: IEvent[] | []
}

const MessageBoxContext = React.createContext<IMessageBoxContext | undefined>(
  undefined,
)

export const useMessageBoxContext = () => {
  const context = React.useContext(MessageBoxContext)
  if (context === undefined) {
    throw new Error(
      'useMessageBoxContext must be used within MessageBoxContextProvider',
    )
  }
  return context
}

const MessageBox = () => {
  const [messages, setMessages] = React.useState<TMessage[]>([])
  const [events, setEvents] = React.useState<IEvents | undefined>(undefined)
  const [lastEvents, setLastEvents] = React.useState<IEvent[]>([])
  const [play] = useSound(notificationSound)
  const messageBoxOpen = useBoundStore((state) => state.messageBoxOpen)
  const addNotification = useBoundStore((state) => state.addNotification)
  const shouldPlay = useBoundStore((state) => state.notificationSound)

  const queryClient = useQueryClient()
  const [, setCookies] = useCookies([socketIdCookieKey])
  const welcomeMessage = queryClient.getQueryData<TMessage>(
    queryKeys.welcomeMessage,
  )
  const pastMessages = queryClient.getQueryData<TMessage[]>(
    queryKeys.pastMessages,
  )
  const reply = queryClient.getQueryData<TMessage>(queryKeys.reply)

  const eventsList = queryClient.getQueryData<IEvents>(queryKeys.events)

  const singleEvent = queryClient.getQueryData<IEvent>(queryKeys.event)

  React.useEffect(() => {
    const isNewReply = !messages.find((message) => message.id === reply?.id)
    const isNewEvent = !lastEvents.find(
      (event) =>
        event.user_id === singleEvent?.user_id &&
        event.type === singleEvent.type,
    )

    if (pastMessages && messages.length === 0) {
      return setMessages(pastMessages)
    }
    if (reply && isNewReply) {
      const replyItems = reply?.message?.items
      const replyItemsSize = replyItems?.length
      const lastItem =
        replyItems && replyItemsSize ? replyItems[replyItemsSize - 1] : null
      const eventsKeys = Object.keys(lastItem?.action?.events || {})
      const needsApiCall = lastItem?.action?.type === 'API'
      if (eventsKeys.length) {
        const startDate = format(
          startOfWeek(new Date(eventsKeys[0]), {
            weekStartsOn: 1,
          }),
          'yyyy-MM-dd',
        )
        const endDate = format(addDays(new Date(startDate), 7), 'yyyy-MM-dd')
        queryClient.removeQueries({ queryKey: ['dates'] })

        setTimeout(() => {
          queryClient.setQueryData(
            ['dates', startDate, endDate],
            lastItem?.action.events,
          )
        }, 1000)
      }

      if (needsApiCall) {
        queryClient.removeQueries({ queryKey: ['dates'] })
      }

      if (!messageBoxOpen) {
        addNotification()
        shouldPlay && play()
      }

      return setMessages((oldMessages) => {
        const newMessages = [...oldMessages, reply]
        const uniqueMessages = getUniqueArray({ array: newMessages, key: 'id' })
        return uniqueMessages
      })
    }
    if (welcomeMessage && messages.length === 0) {
      return setMessages([welcomeMessage])
    }

    if (singleEvent && isNewEvent) {
      const newEvents = [...lastEvents]
      newEvents.push(singleEvent)
      setLastEvents(newEvents)
    }

    setEvents(eventsList)
  }, [
    welcomeMessage,
    pastMessages,
    reply,
    messages,
    eventsList,
    addNotification,
    play,
    messageBoxOpen,
    shouldPlay,
    singleEvent,
    lastEvents,
    queryClient,
  ])

  React.useEffect(() => {
    if (singleEvent?.type === 'START_OVER') {
      setMessages([])
      queryClient.clear()
      setCookies(socketIdCookieKey, singleEvent?.user_id, {
        secure: process.env.NODE_ENV === 'production',
        sameSite: process.env.NODE_ENV === 'production',
      })
    }
  }, [queryClient, setCookies, singleEvent])

  return (
    <MessageBoxContext.Provider
      value={{
        messages,
        updateMessages: setMessages,
        events,
        lastEvents,
      }}
    >
      <div
        id="chat-box"
        className={`tl ${
          messageBoxOpen
            ? 'tl-animate-openbox tl-opacity-100 tl-bottom-0 tl-visible'
            : 'tl-animate-hidebox tl-opacity-0 tl-bottom-8 tl-invisible'
        } tl-flex tl-flex-col tl-fixed tl-left-0 tl-top-0 tl-w-full tl-shadow-md tl-rounded-md sm:tl-flex sm:tl-flex-col sm:tl-absolute sm:tl-top-auto sm:tl-left-auto sm:tl-w-[375px] sm:tl-h-[600px]`}
      >
        <MessageBoxHeader />
        <MessageBoxChat />
        <MessageBoxFooter />
      </div>
    </MessageBoxContext.Provider>
  )
}

export { MessageBox }

import { TMessage } from '../interfaces'

type CreateNewMessageProps = {
  id: string
  text: string
  senderId: string
  timestamp: number
  clientId: string
}

const createNewMessage = ({
  id,
  text,
  senderId,
  timestamp,
  clientId,
}: CreateNewMessageProps) => {
  const recipient = { id: clientId }
  const sender = { id: `${clientId}-${senderId}` }

  const message: TMessage = {
    id,
    recipient,
    sender,
    timestamp,
    message: {
      display: 'TEXT',
      title: text,
      chat_enabled: true,
      type: 'TEXT',

      // TODO: remove the below lines once backend finishes updates
      text,
    },
    kind: 'USER',

    // TODO: remove the below lines once backend finishes updates
    text,
    type: 'USER',
  }

  return message
}

export { createNewMessage }

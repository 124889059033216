import { create } from 'zustand'
import { IMessageBoxSlice, createMessageBoxSlice } from './messageBoxSlice'
import {
  INotificationsSlice,
  createNotificationsSlice,
} from './notificationsSlice'
import {
  IWelcomeMessageSlice,
  createWelcomeMessageSlice,
} from './welcomeMessageSlice'
import { IChatCongfigSlice, createConfigSlice } from './chatConfigSlice'
import { IFormSlice, createFormSlice } from './formSlice'

const useBoundStore = create<
  IMessageBoxSlice &
    INotificationsSlice &
    IWelcomeMessageSlice &
    IChatCongfigSlice &
    IFormSlice
>()((...a) => ({
  ...createMessageBoxSlice(...a),
  ...createNotificationsSlice(...a),
  ...createWelcomeMessageSlice(...a),
  ...createConfigSlice(...a),
  ...createFormSlice(...a),
}))

export { useBoundStore }

import { useQueryClient } from '@tanstack/react-query'

import { useBoundStore } from '../../store'
import { IPersonalization } from '../../interfaces'
import { queryKeys } from '../../constants'
import { ChevronDown } from '../../assets'

const MessageBoxHeader = () => {
  const toggleMessageBoxOpen = useBoundStore(
    (state) => state.toggleMessageBoxOpen,
  )

  const queryClient = useQueryClient()
  const personalization = queryClient.getQueryData<IPersonalization>(
    queryKeys.personalization,
  )

  const handleClose = () => {
    toggleMessageBoxOpen()
    localStorage.setItem('tl-bubble', 'hide')
  }

  return (
    <div className="tl tl-flex tl-justify-between tl-bg-brand tl-text-white tl-px-6 tl-py-4 sm:tl-rounded-t-md">
      <div className="tl">
        <p className="tl tl-text-sm tl-leading-4 tl-font-semibold tl-antialiased">
          {personalization?.business_name}
        </p>
        <p className="tl tl-text-xs tl-leading-4 tl-font-medium tl-uppercase tl-antialiased">
          {personalization?.assistant_name}
        </p>
      </div>
      <div
        onClick={handleClose}
        className="tl tl-flex tl-flex-wrap tl-content-center tl-justify-center tl-w-7 tl-h-7 tl-bg-white/25 tl-rounded-full tl-self-center tl-cursor-pointer"
      >
        <ChevronDown />
      </div>
    </div>
  )
}

export { MessageBoxHeader }

import { QueryKey } from '@tanstack/react-query'

const initialState: QueryKey = ['/preferences/initialState']
const personalization: QueryKey = ['/preferences/personalization']
const pastMessages: QueryKey = ['/messages/past']
const welcomeMessage: QueryKey = ['/message/welcome']
const reply: QueryKey = ['/message/reply']
const events: QueryKey = ['/messages/events']
const event: QueryKey = ['/message/event']

const queryKeys = {
  initialState,
  personalization,
  pastMessages,
  welcomeMessage,
  reply,
  events,
  event,
}

export { queryKeys }

import * as React from 'react'
import { useMessageBoxContext } from './MessageBox'
import { Message } from './Message'
import { AgentTyping } from '../AgentTyping'

const MessageBoxChat = () => {
  const bottomRef = React.useRef<HTMLDivElement>(null)
  const { messages, events } = useMessageBoxContext()

  React.useEffect(() => {
    bottomRef?.current?.scrollIntoView()

    if (events?.isAgentTyping) bottomRef?.current?.scrollIntoView()
  }, [messages, events])

  return (
    <div className="tl tl-flex-1 tl-bg-gray-100 tl-overflow-y-scroll scrollbar-hide">
      <ol className="tl-p-6 tl-flex tl-flex-col tl-gap-2 tl-mt-0 !tl-m-0">
        {messages?.map((message) => (
          <Message key={message?.id} message={message} />
        ))}
        <AgentTyping />
      </ol>
      <div className="tl" ref={bottomRef}></div>
    </div>
  )
}

export { MessageBoxChat }

import { ListItem } from '../ListItem'
import { IItem, ListDialog } from '../Dialogs'

type InlineOptionsButtonProps = {
  title: string
  emptySearch: string | undefined
  items: IItem[] | undefined
  minSelection: number
  maxSelection: number
  subtitle?: string
  actionTitle?: string
  searchable?: boolean
  searchPlaceholder?: string
  openTitle?: string
  icon?: string | undefined
  nextMsg?: boolean | undefined
}

export const InlineOptionsButton = ({
  title,
  subtitle,
  items,
  actionTitle,
  searchable,
  searchPlaceholder,
  openTitle,
  icon,
  emptySearch,
  minSelection,
  maxSelection,
  nextMsg,
}: InlineOptionsButtonProps) => {
  return (
    <ListDialog
      title={title}
      actionTitle={actionTitle}
      searchable={searchable}
      items={items}
      searchPlaceholder={searchPlaceholder}
      notFoundString={emptySearch}
      minSelection={minSelection}
      maxSelection={maxSelection}
      openButton={
        <ListItem useOnlyReceived showLogo>
          <div className="tl tl-bg-gray-300 tl-py-3 tl-pl-3 tl-pr-4 tl-flex tl-flex-col tl-gap-3 tl-rounded-t-md tl-rounded-br-md">
            <div className="tl tl-flex tl-items-center tl-gap-4">
              <div className="tl tl-flex tl-items-center tl-justify-center tl-min-w-[2rem] tl-min-h-[2rem] tl-w-8 tl-h-8 tl-rounded-full tl-object-fill">
                <img src={icon} alt={`${title} icon`} className="tl" />
              </div>
              <div className="tl tl-flex tl-flex-col tl-text-n tl-leading-md">
                <span className="tl tl-font-bold tl-text-gray-900 tl-antialiased">
                  {title}
                </span>
                <span className="tl tl-font-medium tl-antialiased">
                  {subtitle}
                </span>
              </div>
            </div>
            {!nextMsg ? (
              <div className="tl">
                <button className="tl-font-sans tl-border-0 tl-cursor-pointer tl-bg-white tl-py-3 tl-px-8 tl-font-bold tl-text-[#333] tl-rounded-lg tl-text-n tl-leading-md tl-w-full tl-antialiased">
                  {openTitle}
                </button>
              </div>
            ) : null}
          </div>
        </ListItem>
      }
    />
  )
}

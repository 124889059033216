interface ChatContainerProps {
  children: React.ReactNode
}

const ChatContainer = ({ children }: ChatContainerProps) => {
  return (
    <div className="tl tl-font-sans tl-fixed tl-bottom-8 tl-right-8 tl-flex tl-flex-col tl-items-end tl-justify-end tl-gap-4 !tl-z-[2147483002]">
      {children}
    </div>
  )
}

export { ChatContainer }

import * as React from 'react'
import { createPortal } from 'react-dom'
import { Close } from '../../assets'
import { useMessageBoxContext } from '../MessageBox'

interface IPaymentDialogProps {
  title: string
  url: string
  openButton?: React.ReactElement | null
  disableOpen?: boolean
}

export function PaymentDialog({
  title,
  url,
  openButton,
  disableOpen,
}: IPaymentDialogProps) {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const { lastEvents } = useMessageBoxContext()

  React.useEffect(() => {
    const hasCCReceived = lastEvents.filter(
      (ev) => ev.type === 'CC_RECEIVED',
    )[0]

    if (hasCCReceived) {
      setTimeout(() => {
        setIsOpen(false)
      }, 1500)
    }
  }, [lastEvents])

  return (
    <>
      {openButton
        ? React.cloneElement(
            openButton,
            {
              onClick: disableOpen ? () => ({}) : () => setIsOpen(true),
            },
            ...(Array.isArray(openButton.props.children)
              ? openButton.props.children
              : [openButton.props.children]),
          )
        : null}
      {createPortal(
        <dialog
          open={isOpen}
          className={`tl tl-p-0 tl-flex tl-flex-col tl-absolute tl-left-0 tl-w-full tl-h-full tl-z-10 tl-rounded-md ${
            isOpen ? 'tl-top-0' : 'tl-top-[9999rem]'
          }`}
        >
          {/* HEADER */}
          <div className="tl tl-bg-brand tl-px-6 tl-py-4 tl-flex tl-items-center tl-flex-0 tl-justify-between tl-rounded-t-md">
            <span className="tl tl-antialiased tl-text-white tl-font-semibold tl-text-n tl-uppercase">
              {title}
            </span>
            <div
              className="tl tl-cursor-pointer"
              onClick={() => setIsOpen(false)}
            >
              <Close color="#fff" size="14" />
            </div>
          </div>
          {/* CONTENTS */}
          <iframe
            className="tl tl-border-0 tl-w-full tl-h-full"
            src={url}
          ></iframe>
        </dialog>,
        document.getElementById('chat-box') as Element,
      )}
    </>
  )
}

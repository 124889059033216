import Linkify from 'linkify-react'
import { ListItem } from '../ListItem'
import { LinkPreview } from '../LinkPreview'

type ChatMessageTextProps = {
  text: string
  showLogo?: boolean
  metadata?: {
    audio?: string | null
    description?: string
    image?: string
    title?: string
    url?: string
    vide?: string | null
  }
}

const commonClasses = `!tl-text-black !tl-bg-gray-300 
                       tl-rounded-br-md tl-antialiased`

const linkifyOptions = {
  defaultProtocol: 'https',
  rel: 'noreferrer',
  target: '_blank',
}

export const ChatMessageText = ({
  text,
  showLogo = true,
  metadata,
}: ChatMessageTextProps) => {
  const url = text.match(/https?:\/\/[^\s]+/)?.[0]

  if (!url)
    return (
      <ListItem showLogo={showLogo} additionalClasses={commonClasses}>
        {text}
      </ListItem>
    )

  const textWithoutURL = text.replace(/https?:\/\/[^\s]+/, '')
  const endsWithURL = text.endsWith(url)

  if (textWithoutURL && endsWithURL)
    return (
      <>
        <ListItem showLogo={showLogo} additionalClasses={commonClasses}>
          {textWithoutURL}
        </ListItem>
        <LinkPreview metadata={metadata} url={url} />
      </>
    )

  return (
    <ListItem showLogo={showLogo} additionalClasses={commonClasses}>
      <Linkify
        className="tl [&>a]:tl-underline [&>a]:tl-text-gray-900"
        as="span"
        options={linkifyOptions}
      >
        {text}
      </Linkify>
    </ListItem>
  )
}

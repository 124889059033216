import { TMessage } from '../../interfaces'

import {
  ChatMessageText,
  UserMessage,
  InlineOptionsMessage,
  InlineOptionsButton,
  GroupMessage,
} from '../MessageViews'

import { useMessageBoxContext } from './MessageBox'

export interface IMessageProps {
  message: TMessage
}

export const Message = ({ message }: IMessageProps) => {
  const { messages } = useMessageBoxContext()

  const displayAlternatives = messages[messages.length - 1].id === message.id
  const currIndex = messages.findIndex((msg) => msg.id === message.id)
  const prevMsg = messages[currIndex - 1]
  const nextMsg = messages[currIndex + 1]

  const showLogo = () => {
    if (!prevMsg) return true
    if (!nextMsg) return true
    if (prevMsg?.type === 'USER' && nextMsg?.type === 'USER') return true

    return false
  }

  if (message?.type === 'USER') {
    return (
      <UserMessage
        message={message}
        additionalTop={prevMsg?.type !== 'USER'}
        additionalBottom={nextMsg?.type !== 'USER'}
      />
    )
  }

  if (message?.message?.type === 'TEXT') {
    return (
      <ChatMessageText
        text={message?.message?.text}
        metadata={message?.message?.metadata}
      />
    )
  }

  if (
    message?.message?.type === 'INLINE_OPTIONS' &&
    message?.message?.display === 'TEXT'
  ) {
    return (
      <InlineOptionsMessage
        title={message.message.title}
        displayAlternatives={displayAlternatives}
        items={message.message.items}
        showLogo={showLogo()}
      />
    )
  }

  if (
    message?.message?.type === 'INLINE_OPTIONS' &&
    message?.message?.display === 'BUTTON'
  ) {
    return (
      <InlineOptionsButton
        title={message?.message.title}
        subtitle={message?.message?.subtitle}
        items={message?.message?.items?.[0]?.action?.items}
        actionTitle={message?.message?.items?.[0]?.action?.action_title}
        searchable={message?.message?.items?.[0]?.action?.search_enabled}
        searchPlaceholder={message?.message?.items?.[0]?.action?.search_prompt}
        openTitle={message?.message?.items?.[0]?.title}
        icon={message?.message?.leading}
        emptySearch={message?.message?.items?.[0]?.action?.empty_search?.text}
        minSelection={message?.message?.items?.[0]?.action?.min_selection || 1}
        maxSelection={message?.message?.items?.[0]?.action?.max_selection || 1}
        nextMsg={!!nextMsg}
      />
    )
  }

  if (message?.message?.type === 'GROUP') {
    return (
      <GroupMessage
        messageGroup={message.message.messages}
        nextMsg={!!nextMsg}
        messageId={message.id}
      />
    )
  }

  return null
}

import { useQueryClient } from '@tanstack/react-query'

import { queryKeys } from '../../constants'
import { IInitialState } from '../../interfaces'
import { useBoundStore } from '../../store'
import { Close } from '../../assets'

const WelcomeMessage = () => {
  const queryClient = useQueryClient()
  const initialState = queryClient.getQueryData<IInitialState>(
    queryKeys.initialState,
  )
  const showWelcomeMessage = useBoundStore((state) => state.showWelcomeMessage)
  const hideWelcomeMessage = useBoundStore((state) => state.hideWelcomeMessage)
  const messageBoxOpen = useBoundStore((state) => state.messageBoxOpen)

  if (!showWelcomeMessage || messageBoxOpen) return null

  return (
    <div className="tl tl-flex tl-rounded-lg tl-rounded-br-none tl-py-4 tl-pl-4 tl-pr-8 tl-ml-8 tl-max-w-md tl-shadow-md tl-bg-white">
      <div className="tl tl-text-default !tl-text-black tl-antialiased">
        {initialState?.greetingMessage}
      </div>
      <div
        onClick={hideWelcomeMessage}
        className="tl tl-absolute tl-right-2 tl-top-2 tl-cursor-pointer tl-w-4 tl-h-4 tl-flex tl-justify-center tl-items-center"
      >
        <Close />
      </div>
    </div>
  )
}

export { WelcomeMessage }

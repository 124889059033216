import * as React from 'react'
import { useQueryClient } from '@tanstack/react-query'

import { IInitialState } from '../interfaces'
import { queryKeys } from '../constants'
import { useBoundStore } from '../store'

export const useInitialState = () => {
  const queryClient = useQueryClient()
  const initialState = queryClient.getQueryData<IInitialState>(
    queryKeys.initialState,
  )
  const bubbleStatus = localStorage.getItem('tl-bubble')
  const toggleMessageBoxOpen = useBoundStore(
    (state) => state.toggleMessageBoxOpen,
  )
  React.useEffect(() => {
    if (initialState === undefined) return
    if (bubbleStatus !== null) {
      toggleMessageBoxOpen()
    } else if (!initialState?.collapse_on_start) {
      toggleMessageBoxOpen()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialState])
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios'
import { addDays, format, startOfWeek } from 'date-fns'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { IAPICall } from '../../interfaces'
import { apiURL } from '../../constants'
import { CalendarDialog } from '../Dialogs'
import { ListItem } from '../ListItem'

interface IApiMessageProps {
  body?: any
  url?: any
  verb?: any
  openButton: string
}

export const ApiMessage = ({
  body,
  url,
  verb,
  openButton,
}: IApiMessageProps) => {
  const queryClient = useQueryClient()

  const makeAPICall = async ({
    body,
    url,
    verb,
  }: Omit<IAPICall, 'headers'>) => {
    const endpoint = `${apiURL}${url}`
    const today = new Date()
    const startDate = startOfWeek(today, {
      weekStartsOn: 1,
    })
    console.log('🚀 ~ file: ApiMessage.tsx:41 ~ startDate:', startDate)
    const formattedStart = format(startDate, 'yyyy-MM-dd')
    console.log(
      '🚀 ~ file: ApiMessage.tsx:43 ~ formattedStart:',
      formattedStart,
    )
    const endDate = addDays(startDate, 6)
    console.log('🚀 ~ file: ApiMessage.tsx:45 ~ endDate:', endDate)
    const formattedEnd = format(endDate, 'yyyy-MM-dd')
    console.log('🚀 ~ file: ApiMessage.tsx:47 ~ formattedEnd:', formattedEnd)

    try {
      const { data } = await axios({
        method: verb,
        data: {
          ...body,
          start_date: `${formattedStart}`,
          end_date: `${formattedEnd}`,
        },
        url: endpoint,
      })

      queryClient.setQueryData(
        ['dates', formattedStart, formattedEnd],
        data.events,
      )
      return data
    } catch (error) {
      console.log(
        '🚀 ~ file: InlineOptionsMessage.tsx:77 ~ makeAPICall ~ error:',
        error,
      )
      return {}
    }
  }

  const { data } = useQuery({
    queryKey: ['api'],
    queryFn: () => makeAPICall({ body, url, verb }),
  })

  return (
    <CalendarDialog
      title={data?.title}
      actionTitle={data?.actionTitle}
      after={data?.after}
      openButton={
        <ListItem additionalClasses="tl-rounded-xl tl-rounded-t-xl tl-border-brand tl-border tl-text-default tl-text-gray-900 tl-cursor-pointer hover:tl-text-white hover:tl-bg-brand">
          {openButton}
        </ListItem>
      }
    />
  )
}

import { useQueryClient } from '@tanstack/react-query'

import { IPersonalization } from '../../interfaces'
import { queryKeys } from '../../constants'
import { IListItemProps } from './types'

export const ListItem = ({
  additionalClasses,
  children,
  isBot = true,
  onClick,
  showLogo = false,
  useOnlyReceived,
  removeDefaultClasses,
}: IListItemProps) => {
  const queryClient = useQueryClient()
  const personalization = queryClient.getQueryData<IPersonalization>(
    queryKeys.personalization,
  )
  const defaultClasses =
    'tl tl-px-4 tl-py-3 tl-rounded-t-md tl-text-default tl-antialiased'
  const composedClasses = `${removeDefaultClasses ? '' : defaultClasses} ${
    additionalClasses ?? ''
  }`

  const logoInitials = personalization?.business_name.charAt(0)

  return (
    <li
      className={`tl tl-flex tl-gap-2 tl-items-end tl-whitespace-pre-wrap ${
        !isBot ? 'tl-justify-end' : 'tl-mr-5'
      } ${showLogo ? 'tl-ml-0' : 'tl-ml-10'}`}
      onClick={onClick}
    >
      {isBot && showLogo ? (
        <div className="tl tl-flex tl-items-center tl-justify-center tl-min-w-[2rem] tl-min-h-[2rem] tl-w-8 tl-h-8 tl-rounded-full tl-shadow-mid tl-object-fill">
          {personalization?.global_logo_file ? (
            <img
              className="tl tl-rounded-full"
              src={personalization?.global_logo_file}
              alt="Chat icon"
            ></img>
          ) : (
            <span className="tl tl-font-semibold tl-text-sm tl-leading-5 tl-text-gray-900">
              {logoInitials}
            </span>
          )}
        </div>
      ) : null}
      {useOnlyReceived ? (
        children
      ) : (
        <div className={composedClasses}>{children}</div>
      )}
    </li>
  )
}

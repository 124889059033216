/* eslint-disable @typescript-eslint/no-explicit-any */
import { RJSFSchema } from '@rjsf/utils'

export const useJsonSchema = ({ schema }: { schema: RJSFSchema }) => {
  const formData: any = {}
  const formProperties: any = {}
  const localSchema = schema

  if (typeof localSchema.properties === 'object') {
    const keys = Object.keys(localSchema.properties)

    keys.forEach((key) => {
      const obj = localSchema?.properties?.[key] as any

      if (!obj.$ref) {
        const objProperties = Object.keys(obj.properties)

        const keyProperties: any = {}
        const keyFormProperties: any = {}

        objProperties.forEach((property) => {
          keyProperties[property] = ''
          keyFormProperties[property] = obj.properties[property]

          if (
            !keyFormProperties[property]?.required &&
            obj?.required.includes(property)
          ) {
            keyFormProperties[property].required = true
          }
        })

        formData[key] = { ...keyProperties }
        formProperties[key] = { ...keyFormProperties }
        return
      }

      const refKeySplit = obj.$ref.split('/')
      const refKey = refKeySplit[refKeySplit.length - 1]

      const properties = Object.keys(
        (localSchema.$defs?.[refKey] as any).properties,
      )

      const kProperties: any = {}
      const kFormProperties: any = {}

      properties.forEach((p) => {
        kProperties[p] = ''
        kFormProperties[p] = (localSchema.$defs?.[refKey] as any).properties[p]

        if (
          !kFormProperties[p]?.required &&
          (localSchema.$defs?.[refKey] as any)?.required.includes(p)
        ) {
          kFormProperties[p].required = true
        }
      })

      formData[key] = { ...kProperties }
      formProperties[key] = { ...kFormProperties }
    })
  }

  return { formData, formProperties }
}

import * as React from 'react'
import { createPortal } from 'react-dom'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import lottie from 'lottie-web'
import { flatten } from 'lottie-colorify'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'

import { Close, loadingIndicator } from '../../assets'
import { useBoundStore } from '../../store'
import { useCalendar, useReactQuerySubscription } from '../../hooks'
import { SEND_MESSAGE, socketIdCookieKey, apiURL } from '../../constants'
import { v4 } from 'uuid'
import { createNewMessage } from '../../utils'
import { useCookies } from 'react-cookie'
import { useMessageBoxContext } from '../MessageBox'
import { ICalendarDialogProps, IEvent, IEvents } from './dialogInterfaces'
import { IAPICall } from '../../interfaces'

export function CalendarDialog({
  openButton,
  title,
  actionTitle,
  after,
}: ICalendarDialogProps) {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [selectedTime, setSelectedTime] = React.useState<string>('')
  const { sendJsonMessage } = useReactQuerySubscription()
  const [cookies] = useCookies([socketIdCookieKey])
  const brandColor = useBoundStore((state) => state.brandColor)
  const lottieAnimationRef = React.useRef<Element>()
  const dateNumbersRef = React.useRef<HTMLSpanElement[]>([])

  const {
    currentMonth,
    currentYear,
    weekDays,
    advanceOneWeek,
    goBackOneWeek,
    disableBackArrow,
    disableForwardArrow,
    fullCalendar,
    weekStartDate,
    weekEndDate,
    currentWeekDatesInterval,
  } = useCalendar()
  const eventDate = fullCalendar.filter((weekDay) => weekDay.isSelected)[0]
    ?.eventDate

  async function fetchDates(
    startDate: string,
    endDate: string,
    after: IAPICall,
  ) {
    if (!after) return {}

    const endpoint = `${apiURL}${after.url}`
    const { data } = await axios({
      method: after.verb,
      data: {
        ...after.body,
        start_date: `${startDate}`,
        end_date: `${endDate}`,
      },
      url: endpoint,
    })

    const keys = Object.keys(data.events)

    const hasAnyDate = keys.some((key) =>
      currentWeekDatesInterval.includes(key),
    )
    if (!hasAnyDate) return {}

    // Testing placeholder
    // if (!keys.includes(eventDate))
    //   return {
    //     ...data.events,
    //     // '2023-12-18': data.events['2023-12-08'],
    //     '2023-12-19': data.events['2023-12-08'],
    //   }

    return data.events
  }

  const { isLoading, isFetching, data, refetch } = useQuery<IEvents>({
    queryKey: ['dates', weekStartDate, weekEndDate],
    queryFn: () => fetchDates(weekStartDate, weekEndDate, after),
    keepPreviousData: true,
    retry: 1,
    cacheTime: 900000, // 15 mins
    refetchOnWindowFocus: true,
  })
  console.log('🚀 ~ file: CalendarDialog.tsx:98 ~ data:', data)
  const todaysEvents = data?.[eventDate]
  console.log('🚀 ~ file: CalendarDialog.tsx:100 ~ todaysEvents:', todaysEvents)
  const dataKeys = data && Object.keys(data)

  const clientId = useBoundStore((state) => state.clientId)
  const { messages, updateMessages } = useMessageBoxContext()
  const disabled = !selectedTime

  const handleAction = () => {
    const newMessage = {
      type: SEND_MESSAGE,
      id: v4(),
      timestamp: Date.now(),
      data: { id: v4(), text: selectedTime },
    }

    const userMessage = createNewMessage({
      id: newMessage.id,
      text: newMessage.data.text as string,
      senderId: cookies.frontdeskaisocketid,
      timestamp: newMessage.timestamp,
      clientId,
    })

    updateMessages([...messages, userMessage])
    sendJsonMessage(newMessage)
    setIsOpen(false)
  }

  const handleClick = (event: IEvent) => setSelectedTime(event.action.text)

  React.useEffect(() => {
    if (brandColor) {
      const instance = lottie.loadAnimation({
        container: lottieAnimationRef.current as Element,
        animationData: flatten(brandColor, loadingIndicator),
      })

      return () => instance.destroy()
    }
  })

  React.useEffect(() => {
    if (!data) {
      refetch()
    }
  }, [data, refetch])

  const AvailabilityCheck = () => {
    React.useEffect(() => {
      setTimeout(() => {
        const firstAvailableDate = dateNumbersRef?.current
          ?.map((span) => {
            const dataAvailable = span.getAttribute('data-available')
            if (dataAvailable === 'true') return span

            return null
          })
          .filter((el) => el)[0]

        firstAvailableDate?.click()
      }, 1)
    }, [])

    return <>Sorry there&apos;s no availability this week</>
  }

  return (
    <>
      {openButton
        ? React.cloneElement(
            openButton,
            { onClick: () => setIsOpen(true) },
            ...(Array.isArray(openButton.props.children)
              ? openButton.props.children
              : [openButton.props.children]),
          )
        : null}
      {createPortal(
        <dialog
          open={isOpen}
          className={`tl tl-p-0 tl-flex tl-flex-col tl-absolute tl-left-0 tl-w-full tl-h-full tl-z-10 tl-rounded-md ${
            isOpen ? 'tl-top-0' : 'tl-top-[9999rem]'
          }`}
        >
          {/* HEADER */}
          <>
            <div className="tl tl-bg-brand tl-px-6 tl-py-4 tl-flex tl-items-center tl-flex-0 tl-justify-between tl-rounded-t-md">
              <span className="tl tl-antialiased tl-text-white tl-font-semibold tl-text-n tl-uppercase">
                {title}
              </span>
              <div
                className="tl tl-cursor-pointer"
                onClick={() => setIsOpen(false)}
              >
                <Close color="#fff" size="14" />
              </div>
            </div>
            <div className="tl tl-flex tl-flex-col tl-items-center tl-justify-center tl-mt-4 tl-px-3 tl-border-b tl-border-[#C9C9C9] tl-pb-3">
              <div className="tl tl-mb-3">
                <span className="tl tl-capitalize tl-font-semibold tl-text-sm tl-leading-default tl-text-gray-700">
                  {currentMonth} {currentYear}
                </span>
              </div>
              <div className="tl tl-flex tl-items-end tl-w-[280px]">
                <div className="tl tl-flex-1">
                  <div className="tl tl-grid tl-grid-cols-7 tl-grid-rows-1 tl-place-items-center">
                    {weekDays.map((day) => (
                      <span
                        key={day}
                        className={`tl tl-uppercase tl-flex tl-items-center tl-justify-center tl-font-medium tl-text-xs tl-leading-3 tl-text-[#5F6A79]`}
                      >
                        {day}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
              <div className="tl-flex tl-gap-5 tl-w-[345px] tl-max-w-full">
                <div
                  className={`tl-prev noSelect tl tl-w-[34px] tl-h-[34px] tl-flex tl-items-center tl-justify-center tl-cursor-pointer ${
                    disableBackArrow
                      ? 'tl-text-gray-400 !tl-cursor-not-allowed'
                      : ''
                  }`}
                >
                  <FaChevronLeft size={14} />
                </div>
                <Swiper
                  navigation={{
                    nextEl: '.tl-next',
                    prevEl: '.tl-prev',
                  }}
                  modules={[Navigation]}
                  slidesPerView={7}
                  slidesPerGroup={7}
                  spaceBetween={4}
                  onSlideNextTransitionStart={advanceOneWeek}
                  onSlidePrevTransitionStart={goBackOneWeek}
                >
                  {fullCalendar.map((day, index) => (
                    <SwiperSlide
                      key={day.date.toISOString()}
                      className="tl-flex tl-items-center tl-justify-center"
                    >
                      <span
                        ref={(element) => {
                          if (
                            dateNumbersRef &&
                            dateNumbersRef.current &&
                            element
                          ) {
                            dateNumbersRef.current[index] = element
                          }
                        }}
                        onClick={
                          dataKeys?.includes(day.eventDate)
                            ? day.onClick
                            : () => ({})
                        }
                        data-available={dataKeys?.includes(day.eventDate)}
                        className={`${day.isToday ? 'tl-bg-gray-300' : ''} ${
                          isLoading ||
                          isFetching ||
                          !after ||
                          day.isBeforeToday ||
                          !dataKeys?.includes(day.eventDate)
                            ? '!tl-text-gray-400 !tl-cursor-not-allowed'
                            : 'sm:hover:tl-bg-brand sm:hover:tl-text-white'
                        } ${
                          day.isSelected ? '!tl-bg-brand !tl-text-white' : ''
                        } tl tl-w-full tl-h-full tl-max-h-6 tl-max-w-6 tl-border tl-border-transparent tl-rounded-full tl-flex tl-items-center tl-justify-center tl-font-semibold tl-text-sm tl-leading-default tl-cursor-pointer tl-text-gray-700 tl-p-4 noSelect`}
                      >
                        {day.dayNumber}
                      </span>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div
                  className={`tl-next noSelect tl tl-text-gray-700 tl-w-[34px] tl-h-[34px] tl-flex tl-items-center tl-justify-center tl-cursor-pointer ${
                    disableForwardArrow
                      ? '!tl-text-gray-400 !tl-cursor-not-allowed'
                      : ''
                  }`}
                >
                  <FaChevronRight size={14} />
                </div>
              </div>
            </div>
          </>
          {/* CONTENTS */}
          <div className="tl tl-flex tl-flex-col tl-flex-auto tl-overflow-y-auto scrollbar scrollbar-w-[5px] scrollbar-rounded-full scrollbar-thumb-gray-400">
            <div className="tl tl-p-6 tl-pt-4 tl-flex tl-flex-col tl-gap-4 tl-justify-center tl-items-center tl-flex-auto">
              {isLoading || isFetching || !after ? (
                <div className="tl tl-flex tl-flex-col tl-justify-center tl-items-center">
                  <div
                    className="tl tl-h-10 tl-w-10 tl-min-h-10 tl-min-w-10"
                    ref={lottieAnimationRef as React.LegacyRef<HTMLDivElement>}
                  />
                  <div className="tl tl-text-n tl-leading-4 tl-text-gray-700 tl-font-medium">
                    Checking availability
                  </div>
                </div>
              ) : todaysEvents?.length ? (
                todaysEvents?.map((event, i) => (
                  <div
                    onClick={() => handleClick(event)}
                    key={`${event.subtitle}-${i}`}
                    className={`tl tl-py-3 tl-w-full tl-text-center tl-cursor-pointer tl-bg-gray-300/60 tl-rounded-md tl-text-default tl-border tl-border-transparent sm:hover:tl-border-brand sm:hover:tl-bg-white ${
                      selectedTime === event.action.text
                        ? '!tl-border-brand tl-bg-white'
                        : ''
                    }`}
                  >
                    <span className="tl tl-font-semibold">{event.title}</span>{' '}
                    {event.subtitle ? `(${event.subtitle})` : null}
                  </div>
                ))
              ) : (
                <div className="tl tl-text-default">
                  <AvailabilityCheck />
                </div>
              )}
            </div>
          </div>
          {/* FOOTER */}
          <div className="tl tl-p-5 tl-bg-white flex-0">
            <button
              onClick={handleAction}
              className={`tl-font-sans tl-border-0 tl-text-white tl-w-full tl-rounded-md tl-py-2 tl-text-sm tl-font-semibold tl-antialiased ${
                !disabled
                  ? 'tl-bg-brand tl-cursor-pointer'
                  : 'tl-bg-[#B1B9C7] tl-cursor-not-allowed'
              }`}
              disabled={disabled}
            >
              {actionTitle || 'Send'}
            </button>
          </div>
        </dialog>,
        document.getElementById('chat-box') as Element,
      )}
    </>
  )
}

import { StateCreator } from 'zustand'

interface IWelcomeMessageSlice {
  showWelcomeMessage: boolean
  hideWelcomeMessage: () => void
}

const welcomeMessageStatus = localStorage.getItem('tl-welcomeMessage')

const shouldShowWelcomeMessage = welcomeMessageStatus === null

const createWelcomeMessageSlice: StateCreator<
  IWelcomeMessageSlice,
  [],
  [],
  IWelcomeMessageSlice
> = (set) => ({
  showWelcomeMessage: shouldShowWelcomeMessage,
  hideWelcomeMessage: () => {
    localStorage.setItem('tl-welcomeMessage', 'hide')
    set(() => ({ showWelcomeMessage: false }))
  },
})

export type { IWelcomeMessageSlice }
export { createWelcomeMessageSlice }

export const loadingIndicator = {
  nm: 'loading_ring_medium',
  ddd: 0,
  h: 200,
  w: 200,
  meta: { g: '@lottiefiles/toolkit-js 0.33.2' },
  layers: [
    {
      ty: 4,
      nm: 'green ring 1',
      sr: 1,
      st: -1.00000004073083,
      op: 50.0000020365418,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [200, 200, 100] },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [100, 100, 0] },
        r: {
          a: 1,
          k: [
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [0],
              t: 0,
            },
            { s: [360], t: 49.0000019958109 },
          ],
        },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Ellipse 1',
          np: 3,
          it: [
            {
              ty: 'el',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Ellipse',
              nm: 'Ellipse Path 1',
              d: 1,
              p: { a: 0, k: [0, 0] },
              s: { a: 0, k: [54, 54] },
            },
            {
              ty: 'st',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 2,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100 },
              w: { a: 0, k: 6 },
              c: { a: 0, k: [0.4902, 0.3961, 0.9961] },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'tm',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Filter - Trim',
          nm: 'Trim Paths 1',
          ix: 2,
          e: {
            a: 1,
            k: [
              { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [0], t: -1 },
              { s: [100], t: 37.0000015070409 },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          s: {
            a: 1,
            k: [
              { o: { x: 0.644, y: 0 }, i: { x: 0.667, y: 1 }, s: [0], t: 10 },
              { s: [100], t: 50.0000020365418 },
            ],
            ix: 1,
          },
          m: 1,
        },
      ],
      ind: 1,
    },
    {
      ty: 4,
      nm: 'flamingo ring 3',
      sr: 1,
      st: -1.00000004073083,
      op: 44.0000017921567,
      ip: 17.0000006924242,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [0, 0, 0] },
        r: {
          a: 1,
          k: [
            { o: { x: 1, y: 0 }, i: { x: 0.785, y: 1 }, s: [14.2], t: 17 },
            { s: [360], t: 50.0000020365418 },
          ],
        },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Ellipse 1',
          np: 3,
          it: [
            {
              ty: 'el',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Ellipse',
              nm: 'Ellipse Path 1',
              d: 1,
              p: { a: 0, k: [0, 0] },
              s: { a: 0, k: [54, 54] },
            },
            {
              ty: 'st',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 2,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100 },
              w: { a: 0, k: 6 },
              c: { a: 0, k: [0.4902, 0.3961, 0.9961] },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'tm',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Filter - Trim',
          nm: 'Trim Paths 1',
          ix: 2,
          e: {
            a: 1,
            k: [
              { o: { x: 0.333, y: 0 }, i: { x: 0.833, y: 1 }, s: [0], t: 20 },
              { s: [1], t: 44.0000017921567 },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [0],
                t: 42,
              },
              { s: [1], t: 44.0000017921567 },
            ],
            ix: 1,
          },
          m: 1,
        },
      ],
      ind: 2,
      parent: 1,
    },
    {
      ty: 4,
      nm: 'flamingo ring 2',
      sr: 1,
      st: -1.00000004073083,
      op: 44.0000017921567,
      ip: 17.0000006924242,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [0, 0, 0] },
        r: {
          a: 1,
          k: [
            { o: { x: 1, y: 0 }, i: { x: 0.612, y: 1 }, s: [14.2], t: 17 },
            { s: [360], t: 50.0000020365418 },
          ],
        },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Ellipse 1',
          np: 3,
          it: [
            {
              ty: 'el',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Ellipse',
              nm: 'Ellipse Path 1',
              d: 1,
              p: { a: 0, k: [0, 0] },
              s: { a: 0, k: [54, 54] },
            },
            {
              ty: 'st',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 2,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100 },
              w: { a: 0, k: 6 },
              c: { a: 0, k: [0.4902, 0.3961, 0.9961] },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'tm',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Filter - Trim',
          nm: 'Trim Paths 1',
          ix: 2,
          e: {
            a: 1,
            k: [
              { o: { x: 0.333, y: 0 }, i: { x: 0.833, y: 1 }, s: [0], t: 20 },
              { s: [13.7], t: 44.0000017921567 },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [0],
                t: 42,
              },
              { s: [13.7], t: 44.0000017921567 },
            ],
            ix: 1,
          },
          m: 1,
        },
      ],
      ind: 3,
      parent: 1,
    },
    {
      ty: 4,
      nm: 'flaming ring 1',
      sr: 1,
      st: -1.00000004073083,
      op: 44.0000017921567,
      ip: 15.0000006109625,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [0, 0, 0] },
        r: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Ellipse 1',
          np: 3,
          it: [
            {
              ty: 'el',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Ellipse',
              nm: 'Ellipse Path 1',
              d: 1,
              p: { a: 0, k: [0, 0] },
              s: { a: 0, k: [54, 54] },
            },
            {
              ty: 'st',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 2,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100 },
              w: { a: 0, k: 6 },
              c: { a: 0, k: [0.4902, 0.3961, 0.9961] },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'tm',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Filter - Trim',
          nm: 'Trim Paths 1',
          ix: 2,
          e: {
            a: 1,
            k: [
              { o: { x: 1, y: 0 }, i: { x: 0.667, y: 1 }, s: [0], t: -1 },
              { s: [100], t: 37.0000015070409 },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          s: {
            a: 1,
            k: [
              { o: { x: 1, y: 0 }, i: { x: 0.667, y: 1 }, s: [0], t: 8 },
              { s: [100], t: 48.0000019550801 },
            ],
            ix: 1,
          },
          m: 1,
        },
      ],
      ind: 4,
      parent: 1,
    },
  ],
  v: '4.6.0',
  fr: 29.9700012207031,
  op: 49.0000019958109,
  ip: 0,
  assets: [],
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isElementScrollable = ({ element }: { element: any }): boolean => {
  if (!element) return false

  const elHeight = element?.scrollHeight
  const elClientHeight = element?.clientHeight

  if (elHeight && elClientHeight) {
    return elHeight > elClientHeight
  }

  return false
}

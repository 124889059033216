import { TMessage } from '../../interfaces'

import { ListItem } from '../ListItem'

type UserMessageProps = {
  message: TMessage
  additionalTop: boolean
  additionalBottom: boolean
}

export const UserMessage = ({
  message,
  additionalTop,
  additionalBottom,
}: UserMessageProps) => {
  return (
    <ListItem
      additionalClasses={`tl-bg-brand tl-text-white tl-rounded-bl-md ${
        additionalTop ? 'tl-mt-2' : ''
      } ${additionalBottom ? 'tl-mb-2' : ''}`}
      isBot={false}
    >
      {message?.text}
    </ListItem>
  )
}

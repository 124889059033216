import { useBoundStore } from '../../store'

const NotificationsCount = () => {
  const notificationsCount = useBoundStore((state) => state.notificationsCount)

  if (!notificationsCount) return null

  return (
    <div className="tl tl-absolute tl-left-11 tl-flex tl-h-6 tl-w-6 tl-items-center tl-justify-center tl-rounded-full tl-border tl-border-gray-800 tl-bg-white tl-text-gray-700 tl-text-n tl-font-bold">
      {notificationsCount}
    </div>
  )
}

export { NotificationsCount }

const Close = ({ color, size }: { color?: string; size?: string }) => {
  return (
    <svg
      className="tl"
      width={size ?? '12'}
      height={size ?? '12'}
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.238 1.524L7.062 5.7L11.238 9.876L10.176 10.938L6 6.762L1.824 10.938L0.762 9.876L4.938 5.7L0.762 1.524L1.824 0.461999L6 4.638L10.176 0.461999L11.238 1.524Z"
        fill={color || '#9B9B9B'}
      />
    </svg>
  )
}

export { Close }

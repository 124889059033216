const colors = {
  white: '#FFF',
  gray: {
    50: '#DAE0E3',
    100: '#fafafa',
    200: '#e1e0e0',
    300: '#e8ecf3',
    400: '#c2c7cc',
    500: '#959fac',
    600: '#506c7c',
    700: '#46474d',
    800: '#CECCCC',
    900: '#32323a',
  },
}

export { colors }

import { StateCreator } from 'zustand'

interface IMessageBoxSlice {
  messageBoxOpen: boolean
  toggleMessageBoxOpen: () => void
}
const bubbleStatus = localStorage.getItem('tl-bubble')

const shouldShowBubble = bubbleStatus === null

const createMessageBoxSlice: StateCreator<
  IMessageBoxSlice,
  [],
  [],
  IMessageBoxSlice
> = (set) => ({
  messageBoxOpen: !shouldShowBubble,
  toggleMessageBoxOpen: () => {
    set((state) => ({ messageBoxOpen: !state.messageBoxOpen }))
  },
})

export type { IMessageBoxSlice }
export { createMessageBoxSlice }

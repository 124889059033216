/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'
import { createPortal } from 'react-dom'
import { RJSFSchema } from '@rjsf/utils'
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js'

import { Close } from '../../assets'
import { SEND_MESSAGE } from '../../constants'
import { v4 } from 'uuid'
import {
  createNewMessage,
  generateFormResponse,
  isElementScrollable,
} from '../../utils'
import { useCookies } from 'react-cookie'
import { useBoundStore } from '../../store'
import { useJsonSchema, useReactQuerySubscription } from '../../hooks'
import { useMessageBoxContext } from '../MessageBox'
import { useForm } from 'react-hook-form'
import { useMask } from '@react-input/mask'

interface IFormDialogProps {
  openButton?: React.ReactElement | null
  title: string
  schema: RJSFSchema
  actionTitle?: string
  disableOpen?: boolean
  messageId?: string
}

function mergeRefs(...refs: any) {
  return (value: any) => {
    for (const ref of refs) {
      if (typeof ref === 'function') {
        ref(value)
      } else if (ref != null) {
        ref.current = value
      }
    }
  }
}

export function FormDialog({
  openButton,
  title,
  actionTitle,
  schema,
  disableOpen,
  messageId = '',
}: IFormDialogProps) {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const { formData, formProperties } = useJsonSchema({ schema })
  const clientId = useBoundStore((state) => state.clientId)
  const addSubmittedForm = useBoundStore((state) => state.addSubmittedForm)
  const { sendJsonMessage } = useReactQuerySubscription()
  const { messages, updateMessages } = useMessageBoxContext()
  const [cookies] = useCookies()
  const contentRef = React.useRef<HTMLDivElement>(null)
  const isContentScrollable = isElementScrollable({
    element: contentRef.current,
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: formData,
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (formData: any) => {
    const newMessage = {
      type: SEND_MESSAGE,
      id: v4(),
      timestamp: Date.now(),
      data: { id: v4(), text: generateFormResponse({ formData }) },
    }

    const userMessage = createNewMessage({
      id: newMessage.id,
      text: generateFormResponse({ formData }),
      senderId: cookies.frontdeskaisocketid,
      timestamp: newMessage.timestamp,
      clientId,
    })

    addSubmittedForm(messageId)
    updateMessages([...messages, userMessage])
    sendJsonMessage(newMessage)

    setIsOpen(false)
  }

  const CustomInput = ({
    inputKeys,
    inputName,
    elementKey,
  }: {
    inputKeys: any
    inputName: any
    elementKey: any
  }) => {
    const inputProps = inputKeys[inputName]

    // Phone mask
    const phoneRef = useMask({
      mask: '___-___-____',
      replacement: { _: /\d/ },
    })

    // Date of Birth mask
    const dobRef = useMask({
      mask: '__/__/____',
      replacement: { _: /\d/ },
    })

    const nameRef = useMask({
      mask: '___________________________________________',
      replacement: { _: /^[A-Za-z\s]*$/ },
    })

    switch (inputProps.type) {
      case 'string': {
        const name = `${elementKey}.${inputName}`
        const placeholder = inputProps.description || ''
        const error = errors?.[elementKey]
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const errorMessage = error?.[inputName]
        const hasError = errorMessage !== undefined
        const required =
          inputProps?.minLength ||
          inputProps?.required?.length ||
          inputProps.required
        const requiredMessage =
          inputProps?.required?.[0] || 'This field is required'
        const minLengthMessage = `This field should have at least ${inputProps?.minLength} character(s)`

        const field = register(name, {
          required: {
            value: required,
            message: requiredMessage,
          },
          minLength: {
            value: inputProps.minLength,
            message: minLengthMessage,
          },
          pattern: {
            value:
              inputProps.format === 'email'
                ? /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g
                : /(?:)/,
            message: 'Please enter a valid email address.',
          },
          validate: (value) => {
            // default fields
            if (!value && !required) return true

            const emptyField = value.trim()
            if (!emptyField && required) return 'Field cannot be empty'

            // phone validation
            if (inputProps.format === 'phone') {
              const phoneLength = value.length
              if (value && phoneLength !== 12) {
                return 'Enter a valid phone number'
              }

              const parsedUSPhone = parsePhoneNumber(value, 'US')
              const parsedCAPhone = parsePhoneNumber(value, 'CA')
              const validUSPhone = isValidPhoneNumber(
                parsedUSPhone.number,
                'US',
              )
              const validCAPhone = isValidPhoneNumber(
                parsedCAPhone.number,
                'CA',
              )
              const validPhone = validUSPhone || validCAPhone
              return validPhone ? true : 'Enter a valid phone number'
            }

            if (inputProps.format === 'date') {
              const dateLength = value.length
              if (dateLength !== 10) return 'Enter a valid date'

              const year = value.split('/')[2]
              const yearNumber = Number(year)
              const date = new Date(value)
              const today = new Date()
              const maxAge = 100
              const todaysYear = today.getFullYear()

              const yrsDifference = todaysYear - yearNumber
              const isMoreThan100 = yrsDifference > maxAge

              if (isNaN(date.getDate()) || date > today || isMoreThan100) {
                return 'Enter a valid date'
              }

              return true
            }

            // in case something's off
            return true
          },
        })

        const getRef = () => {
          if (inputProps.format === 'phone')
            return mergeRefs(field.ref, phoneRef)
          if (inputProps.format === 'date') return mergeRefs(field.ref, dobRef)
          if (/name/i.test(inputProps.title))
            return mergeRefs(field.ref, nameRef)

          return field.ref
        }

        return (
          <div className="tl tl-flex tl-flex-col tl-gap-1 tl-mb-5" key={name}>
            <label
              htmlFor={name}
              className="tl tl-text-n tl-leading-md tl-text-[#4A4A4A] tl-font-medium"
            >
              {inputProps.title}
              {required ? '*' : null}
            </label>
            <input
              className={`${
                hasError ? 'tl-border-error' : ''
              } tl tl-font-sans !tl-py-3 !tl-pl-3 tl-text-n tl-rounded-md tl-border tl-border-[#CFD0D1] placeholder:tl-text-n`}
              id={name}
              placeholder={placeholder}
              {...field}
              ref={getRef()}
            />
            {hasError ? (
              <p className="tl tl-font-medium tl-text-[9px] tl-leading-3 tl-uppercase tl-text-error">
                {errorMessage.message}
              </p>
            ) : null}
          </div>
        )
      }
      default: {
        return null
      }
    }
  }

  return (
    <>
      {openButton
        ? React.cloneElement(
            openButton,
            {
              onClick: disableOpen ? () => ({}) : () => setIsOpen(true),
            },
            ...(Array.isArray(openButton.props.children)
              ? openButton.props.children
              : [openButton.props.children]),
          )
        : null}
      {createPortal(
        <dialog
          open={isOpen}
          className={`tl tl-p-0 tl-flex tl-flex-col tl-absolute tl-left-0 tl-w-full tl-h-full tl-z-10 tl-rounded-md ${
            isOpen ? 'tl-top-0' : 'tl-top-[9999rem]'
          }`}
        >
          {/* HEADER */}
          <div className="tl tl-bg-brand tl-px-6 tl-py-4 tl-flex tl-items-center tl-flex-0 tl-justify-between tl-rounded-t-md">
            <span className="tl tl-antialiased tl-text-white tl-font-semibold tl-text-n tl-uppercase">
              {title}
            </span>
            <div
              className="tl tl-cursor-pointer"
              onClick={() => setIsOpen(false)}
            >
              <Close color="#fff" size="14" />
            </div>
          </div>
          {/* CONTENTS */}
          <div
            ref={contentRef}
            className="tl tl-flex-auto tl-p-6 tl-pt-2 tl-overflow-y-auto scrollbar scrollbar-w-[5px] scrollbar-rounded-full scrollbar-thumb-gray-400"
          >
            <form
              id={`form-dialog-${messageId}`}
              onSubmit={handleSubmit(onSubmit)}
            >
              {Object.keys(formProperties).map((elementKey) => {
                const inputKeys = formProperties[elementKey]
                return (
                  <>
                    <div className="tl tl-uppercase tl-text-n tl-leading-md tl-text-gray-700 tl-font-semibold tl-mb-4 tl-mt-2">
                      {elementKey.replace('_', ' ')}
                    </div>
                    {Object.keys(inputKeys).map((inputName) => (
                      <CustomInput
                        key={`${elementKey}.${inputName}`}
                        inputKeys={inputKeys}
                        inputName={inputName}
                        elementKey={elementKey}
                      />
                    ))}
                  </>
                )
              })}
            </form>
          </div>
          {/* FOOTER */}
          <div
            className={`tl tl-p-5 tl-bg-white flex-0 tl-rounded-t tl-z-10 ${
              isContentScrollable ? 'tl-shadow-mid' : ''
            }`}
          >
            <input
              form={`form-dialog-${messageId}`}
              value={actionTitle || 'Send'}
              readOnly
              type="submit"
              className="tl-font-sans tl-border-0 tl-text-white tl-w-full tl-rounded-md tl-py-2 tl-text-sm tl-font-semibold tl-antialiased tl-bg-brand tl-cursor-pointer"
            />
          </div>
        </dialog>,
        document.getElementById('chat-box') as Element,
      )}
    </>
  )
}

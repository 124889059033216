const ChatRightText = () => {
  return (
    <svg
      className="tl"
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.7051 6.00082C21.7051 2.68666 19.0184 0 15.7043 0H6.00082C2.68666 0 0 2.68666 0 6.00082V12.6983C0 16.0125 2.68666 18.6992 6.00082 18.6992H10.3844H14.768C16.1386 18.6992 17.8786 19.5607 19.9451 21.3479L21.7051 22.87V6.00082ZM15.7044 2.12802C17.8433 2.12802 19.5772 3.86196 19.5772 6.00089V18.3673C17.8501 17.179 16.2531 16.5713 14.7681 16.5713H6.00089C3.86196 16.5713 2.12802 14.8373 2.12802 12.6984V6.00089C2.12802 3.86196 3.86196 2.12802 6.00089 2.12802H15.7044ZM6.17108 8.29893V6.59657H15.8989V8.29893H6.17108ZM6.17108 10.8526V12.555H12.9805V10.8526H6.17108Z"
        fill="white"
      />
    </svg>
  )
}

export { ChatRightText }

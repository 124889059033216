import * as React from 'react'

import {
  useDynamicColors,
  useInitialState,
  useReactQuerySubscription,
} from '../../hooks'
import { Bubble, DisabledBubble } from '../Bubble'
import { MessageBox } from '../MessageBox'
import { WelcomeMessage } from '../WelcomeMessage'

import { ChatContainer } from './ChatContainer'
import { SendMessage, ReadyState } from 'react-use-websocket'
import {
  SendJsonMessage,
  WebSocketLike,
} from 'react-use-websocket/dist/lib/types'
import { useBoundStore } from '../../store'

interface IChatContextProps {
  sendMessage: SendMessage
  sendJsonMessage: SendJsonMessage
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lastMessage: MessageEvent<any> | null
  lastJsonMessage: unknown
  readyState: ReadyState
  getWebSocket: () => WebSocketLike | null
}
const ChatContext = React.createContext<IChatContextProps | undefined>(
  undefined,
)

export const useChatContext = () => {
  const context = React.useContext(ChatContext)
  if (context === undefined) {
    throw new Error('useChatContext must be used within ChatContextProvider')
  }
  return context
}

const Chat = () => {
  const wsInfo = useReactQuerySubscription()
  useDynamicColors()
  useInitialState()
  const chatEnabled = useBoundStore((state) => state.chatEnabled)

  return (
    <ChatContext.Provider value={{ ...wsInfo }}>
      <ChatContainer>
        {chatEnabled ? (
          <>
            <WelcomeMessage />
            <MessageBox />
            <Bubble />
          </>
        ) : (
          <DisabledBubble />
        )}
      </ChatContainer>
    </ChatContext.Provider>
  )
}

export { Chat }

export const MagnifyingGlass = ({
  color,
  size,
}: {
  color?: string
  size?: string
}) => {
  return (
    <svg
      className="tl"
      width={size ?? '20'}
      height={size ?? '20'}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="&#238;&#171;&#147;"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.935 17.6628L18.2642 19.3336L12.7744 13.8173V12.9421L12.4561 12.6504C11.8373 13.1985 11.1256 13.6184 10.3212 13.9101C9.51672 14.2019 8.68132 14.3477 7.81497 14.3477C6.50661 14.3477 5.29992 14.0295 4.19488 13.393C3.08985 12.7565 2.21909 11.899 1.58259 10.8205C0.928408 9.7066 0.601318 8.49549 0.601318 7.18713C0.601318 5.87877 0.923988 4.67208 1.56933 3.56704C2.21467 2.46201 3.08985 1.59125 4.19488 0.954748C5.29992 0.318249 6.50661 0 7.81497 0C9.12333 0 10.3344 0.32709 11.4483 0.981269C12.5268 1.61777 13.3799 2.48411 14.0076 3.58031C14.6352 4.6765 14.9491 5.87877 14.9491 7.18713C14.9491 8.07116 14.8032 8.9154 14.5115 9.71987C14.2197 10.5243 13.7998 11.236 13.2517 11.8548L13.5435 12.173H14.4452L19.935 17.6628ZM5.32174 11.4967C6.082 11.9475 6.91299 12.173 7.81469 12.173C8.7164 12.173 9.54739 11.9475 10.3076 11.4967C11.0679 11.0458 11.669 10.4403 12.1111 9.68001C12.5531 8.91974 12.7741 8.08876 12.7741 7.18705C12.7741 6.28535 12.5531 5.45436 12.1111 4.6941C11.669 3.93384 11.0679 3.3327 10.3076 2.89069C9.54739 2.44867 8.7164 2.22767 7.81469 2.22767C6.91299 2.22767 6.082 2.44867 5.32174 2.89069C4.56148 3.3327 3.95592 3.93384 3.50507 4.6941C3.05421 5.45436 2.82879 6.28535 2.82879 7.18705C2.82879 8.08876 3.05421 8.91974 3.50507 9.68001C3.95592 10.4403 4.56148 11.0458 5.32174 11.4967Z"
        fill={color ?? '#4A4A4A'}
      />
    </svg>
  )
}

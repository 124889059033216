import * as React from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useCookies } from 'react-cookie'
import useWebSocket from 'react-use-websocket'

import { errors, socketIdCookieKey, wsURL } from '../constants'
import { getRandomString } from '../utils'
import { useBoundStore } from '../store'

const useReactQuerySubscription = () => {
  const clientId = useBoundStore((state) => state.clientId)
  const chatEnabled = useBoundStore((state) => state.chatEnabled)
  const setChatEnabled = useBoundStore((state) => state.setChatEnabled)
  const queryClient = useQueryClient()
  const [cookies, setCookie] = useCookies([socketIdCookieKey])

  React.useEffect(() => {
    if (!cookies?.[socketIdCookieKey]) {
      setCookie(socketIdCookieKey, getRandomString(), {
        secure: process.env.NODE_ENV === 'production',
        sameSite: process.env.NODE_ENV === 'production',
      })
    }
  }, [cookies, setCookie])

  const socketURL = `${wsURL}?client_id=${clientId}&user_id=${cookies.frontdeskaisocketid}`

  const socketData = useWebSocket(socketURL, {
    onOpen: () => {
      console.log('%cwebsocket opened', 'color:green')
    },
    onMessage: (event) => {
      if (!chatEnabled) {
        setChatEnabled(true)
      }

      const data = JSON.parse(event.data)
      const queryKey = [data.type].filter(Boolean)

      queryClient.setQueryData(queryKey, data.data)
    },
    shouldReconnect: (closeEvent) => {
      // define if we should reconnect
      const reason = closeEvent.reason
      if (reason === errors.CHAT_DISABLED) return false

      return true
    },
    onClose: (closeEvent) => {
      console.log(closeEvent)

      const reason = closeEvent.reason
      if (reason === errors.CHAT_DISABLED) {
        setChatEnabled(false)
      }

      if (closeEvent?.reason) {
        return console.log(
          `%cwebsocket closed: ${closeEvent?.reason}`,
          'color:red',
        )
      }
      return console.log(
        '%cwebsocket closed due to unknown reason',
        'color:red',
      )
    },
    share: true,
  })

  return { ...socketData }
}

export { useReactQuerySubscription }

import { StateCreator } from 'zustand'

interface IChatCongfigSlice {
  clientId: string
  setClientId: (id: string) => void
  chatEnabled: boolean
  setChatEnabled: (enabled: boolean) => void
  brandColor: string
  setBrandColor: (newColor: string) => void
  notificationSound: boolean
  setNotificationSound: (shouldPlay: boolean) => void
}

const createConfigSlice: StateCreator<
  IChatCongfigSlice,
  [],
  [],
  IChatCongfigSlice
> = (set) => ({
  clientId: '',
  setClientId: (id) => set(() => ({ clientId: id })),
  chatEnabled: false,
  setChatEnabled: (enabled) => set(() => ({ chatEnabled: enabled })),
  brandColor: '',
  setBrandColor: (newColor) => set(() => ({ brandColor: newColor })),
  notificationSound: false,
  setNotificationSound: (shouldPlay) =>
    set(() => ({ notificationSound: shouldPlay })),
})

export type { IChatCongfigSlice }
export { createConfigSlice }

import r2wc from '@r2wc/react-to-web-component'
import '@ungap/custom-elements'

import App from './App'
import { AutoSize } from './components/autosize/autosize'

const WebChat = r2wc(App, {
  props: {
    id: 'string',
    notificationsound: 'boolean',
  },
})

customElements.define('truelark-chat', WebChat)
customElements.define('auto-size', AutoSize, { extends: 'textarea' })

export const typingIndicator = {
  v: '5.9.4',
  fr: 25,
  ip: 0,
  op: 30,
  w: 300,
  h: 150,
  nm: 'Comp 1',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Shape Layer 3',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 0,
              s: [50],
            },
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 10,
              s: [50],
            },
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 20,
              s: [100],
            },
            {
              t: 30,
              s: [50],
            },
          ],
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [244, 75, 0],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [-83.5, 0, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: {
                a: 0,
                k: [53.863, 53.863],
                ix: 2,
              },
              p: {
                a: 0,
                k: [0, 0],
                ix: 3,
              },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.58431372549, 0.623529411765, 0.674509803922, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [-84.635, -0.361],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: 'Transform',
            },
          ],
          nm: 'Ellipse 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 50,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Shape Layer 2',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 0,
              s: [50],
            },
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 10,
              s: [100],
            },
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 20,
              s: [50],
            },
            {
              t: 30,
              s: [50],
            },
          ],
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [152.25, 75, 0],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [-83, 0, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: {
                a: 0,
                k: [53.863, 53.863],
                ix: 2,
              },
              p: {
                a: 0,
                k: [0, 0],
                ix: 3,
              },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.58431372549, 0.623529411765, 0.674509803922, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [-84.635, -0.361],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: 'Transform',
            },
          ],
          nm: 'Ellipse 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 50,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Shape Layer 1',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 0,
              s: [100],
            },
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 10,
              s: [50],
            },
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 20,
              s: [50],
            },
            {
              t: 30,
              s: [100],
            },
          ],
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [58, 75, 0],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [-85, 0, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: {
                a: 0,
                k: [53.863, 53.863],
                ix: 2,
              },
              p: {
                a: 0,
                k: [0, 0],
                ix: 3,
              },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.58431372549, 0.623529411765, 0.674509803922, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [-84.635, -0.361],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: 'Transform',
            },
          ],
          nm: 'Ellipse 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 50,
      st: 0,
      ct: 1,
      bm: 0,
    },
  ],
  markers: [],
}
